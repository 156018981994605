import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import '../styles/settingsstyles.css';
import IntegrationsManagementPage from './IntegrationManagementPage';
import ThemeAppearanceSettings from './ThemeManagementPage';
import NotificationsPage from './NotificationsPage';
import SubscriptionsPage from './SubscriptionPage';
import PrivacyAndSecurityPage from './PrivacyAndSecurityPage';
import HelpAndSupportPage from './HelpAndSupportPage';
//import BackButton from '../components/BackButton'; // Import BackButton component
const settingsSections = [
    {
        title: 'Integrations',
        description: 'Manage integrations like Home Assistant and Alexa.',
        icon: '🔗',
        action: 'integrations',
    },
    {
        title: 'Notifications',
        description: 'Set notification preferences and alerts.',
        icon: '🔔',
        action: 'notifications',
    },
    {
        title: 'Subscriptions',
        description: 'Manage your subscription and billing details.',
        icon: '💳',
        action: 'subscriptions',
    },
    {
        title: 'Theme & Appearance',
        description: 'Toggle dark mode and customize the app theme.',
        icon: '🎨',
        action: 'theme',
    },
    {
        title: 'Privacy & Security',
        description: 'Update passwords and manage privacy settings.',
        icon: '🔒',
        action: 'privacy',
    },
    {
        title: 'Help & Support',
        description: 'Access FAQs, guides, and support resources.',
        icon: '❓',
        action: 'help',
    },
];
const SettingsPage = () => {
    const [activeSection, setActiveSection] = useState(null);
    const [firebaseUid, setFirebaseUid] = useState(null);
    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            try {
                if (user) {
                    setFirebaseUid(user.uid);
                }
                else {
                    setFirebaseUid(null);
                }
            }
            catch (error) {
                console.error('Authentication error:', error);
            }
        });
        return () => unsubscribe();
    }, []);
    const handleSectionClick = (action) => {
        setActiveSection(action);
    };
    const handleBackClick = () => {
        setActiveSection(null);
    };
    // Conditional rendering for each section
    if (activeSection === 'integrations') {
        if (!firebaseUid) {
            return _jsx("div", { children: "Loading..." }); // Show a loading indicator
        }
        return (_jsx("div", { className: "settings-section-page", children: _jsx(IntegrationsManagementPage, { onBack: handleBackClick, firebaseUid: firebaseUid }) }));
    }
    if (activeSection === 'theme') {
        return (_jsx("div", { className: "settings-section-page", children: _jsx(ThemeAppearanceSettings, { onBack: handleBackClick }) }));
    }
    if (activeSection === 'notifications') {
        return (_jsx("div", { className: "settings-section-page", children: _jsx(NotificationsPage, { onBack: handleBackClick }) }));
    }
    if (activeSection === 'subscriptions') {
        return (_jsx("div", { className: "settings-section-page", children: _jsx(SubscriptionsPage, { onBack: handleBackClick }) }));
    }
    if (activeSection === 'privacy') {
        return (_jsx("div", { className: "settings-section-page", children: _jsx(PrivacyAndSecurityPage, { onBack: handleBackClick }) }));
    }
    if (activeSection === 'help') {
        return (_jsx("div", { className: "settings-section-page", children: _jsx(HelpAndSupportPage, { onBack: handleBackClick }) }));
    }
    return (_jsxs("div", { className: "settings-page", children: [_jsx("h1", { children: "Settings" }), _jsx("div", { className: "settings-grid", children: settingsSections.map((section, index) => (_jsxs("div", { className: "settings-card", onClick: () => handleSectionClick(section.action), children: [_jsx("div", { className: "settings-card-icon", children: section.icon }), _jsx("h2", { children: section.title }), _jsx("p", { children: section.description })] }, index))) })] }));
};
export default SettingsPage;
