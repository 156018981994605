import { jsx as _jsx } from "react/jsx-runtime";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend, } from 'chart.js';
// Register the components
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);
const BarChartComponent = ({ labels, data, }) => {
    const chartData = {
        labels,
        datasets: [
            {
                label: 'Points',
                data,
                backgroundColor: 'rgba(75, 192, 192, 0.6)', // Bar color
                borderColor: 'rgba(75, 192, 192, 1)', // Border color
                borderWidth: 1,
            },
        ],
    };
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'top',
                labels: {
                    color: '#FFEB3B', // Font color for legend labels
                    font: {
                        family: "'Bangers', cursive", // Optional: Custom font
                        size: 14,
                    },
                },
            },
            tooltip: {
                enabled: true,
                titleColor: '#FFFFFF', // Tooltip title font color
                bodyColor: '#FFFFFF', // Tooltip body font color
                backgroundColor: '#444', // Tooltip background color
            },
            title: {
                display: true,
                text: 'Completed Chores Overview',
                color: '#FFEB3B', // Chart title font color
                font: {
                    family: "'Bangers', cursive", // Optional: Custom font
                    size: 18,
                },
            },
        },
        scales: {
            x: {
                ticks: {
                    color: '#FFEB3B', // Font color for X-axis labels
                    font: {
                        family: "'Bangers', cursive", // Optional: Custom font
                        size: 14,
                    },
                },
                grid: {
                    color: '#333', // Grid line color for X-axis
                },
            },
            y: {
                ticks: {
                    color: '#FFEB3B', // Font color for Y-axis labels
                    font: {
                        family: "'Bangers', cursive", // Optional: Custom font
                        size: 14,
                    },
                },
                grid: {
                    color: '#333', // Grid line color for Y-axis
                },
            },
        },
    };
    return _jsx(Bar, { data: chartData, options: options });
};
export default BarChartComponent;
