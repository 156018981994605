import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import '../styles/smallcalendar.css';
const SmallCalendar = ({ choreDays }) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    // Helper function to check if a date is in the choreDays array
    const isChoreDay = (date) => {
        const localDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        const formattedDate = localDate.toISOString().split('T')[0]; // Format date as 'YYYY-MM-DD'
        return choreDays.includes(formattedDate);
    };
    // Function to navigate months
    const changeMonth = (offset) => {
        const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + offset, 1);
        setCurrentDate(newDate);
    };
    const renderCalendarDays = () => {
        const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
        const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();
        const calendarDays = [];
        // Padding for days before the first of the month
        for (let i = 0; i < firstDayOfMonth; i++) {
            calendarDays.push(_jsx("div", { className: "empty-day" }, `empty-${i}`));
        }
        // Days of the month
        for (let day = 1; day <= daysInMonth; day++) {
            const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
            const isChore = isChoreDay(date);
            calendarDays.push(_jsx("div", { className: `calendar-day ${isChore ? 'chore-day' : ''}`, children: day }, day));
        }
        return calendarDays;
    };
    return (_jsxs("div", { className: "small-calendar", children: [_jsxs("div", { className: "calendar-header", children: [_jsx("button", { onClick: () => changeMonth(-1), children: "<" }), _jsx("h3", { children: currentDate.toLocaleDateString('default', { month: 'long', year: 'numeric' }) }), _jsx("button", { onClick: () => changeMonth(1), children: ">" })] }), _jsx("div", { className: "calendar-grid", children: renderCalendarDays() })] }));
};
export default SmallCalendar;
