var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import WelcomeVideo from './WelcomeVideo';
import HouseholdSetup from './HouseholdSetup';
import IntegrationSetup from './IntegrationSetup';
import Summary from './Summary';
import '../../styles/wizard.css'; // Ensure the wizard CSS is imported
import axiosInstance from '../../api/axiosInstance';
const OnboardingWizard = ({ onClose, userId }) => {
    const [step, setStep] = useState(1); // Tracks the current step in the wizard
    const [onboardingData, setOnboardingData] = useState({}); // Tracks all collected data
    // Function to handle moving to the next step and saving data in memory
    const handleNext = (stepKey, data) => {
        if (!data || Object.keys(data).length === 0) {
            alert('Please fill out all required fields.');
            return;
        }
        setOnboardingData((prev) => (Object.assign(Object.assign({}, prev), { [stepKey]: data }))); // Update in-memory state
        setStep((prev) => prev + 1); // Move to the next step
        console.log('Data saved for step:', stepKey, data);
    };
    const handleBack = () => setStep((prev) => prev - 1);
    const handleComplete = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const response = yield axiosInstance.post('/api/save-onboarding', {
                firebaseUid: userId,
                stepData: onboardingData, // Send the collected data
            });
            if (response.status === 200) {
                console.log('Onboarding data saved successfully');
                onClose(); // Close the onboarding wizard
            }
            else {
                console.error('Failed to save onboarding data');
            }
        }
        catch (error) {
            console.error('Error saving onboarding data:', error);
        }
    });
    return (_jsx("div", { className: "modal-backdrop", children: _jsxs("div", { className: "modal-container onboarding-wizard", children: [_jsx("button", { className: "close-button", onClick: onClose, children: "\u00D7" }), _jsxs("div", { className: "wizard-header", children: [_jsx("h1", { children: "Onboarding Wizard" }), _jsxs("p", { children: ["Step ", step, " of 4"] })] }), step === 1 && (_jsx(WelcomeVideo, { onNext: (data) => handleNext('step1', data), onSkip: onClose })), step === 2 && (_jsx(HouseholdSetup, { onNext: (data) => handleNext('step2', data), onBack: handleBack })), step === 3 && (_jsx(IntegrationSetup, { onNext: (data) => handleNext('step3', data), onBack: handleBack, userId: userId, onSkip: onClose })), step === 4 && _jsx(Summary, { onComplete: onClose, onBack: handleBack })] }) }));
};
export default OnboardingWizard;
