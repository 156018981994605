import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { createRoot } from 'react-dom/client'; // Correctly import `createRoot`
import App from './app'; // Ensure this is the correct path to your App component
import './styles/mainstyles.css'; // Adjust as per your styles directory structure
import './styles/sidebar.css';
import './styles/loginmodal.css';
// import { Buffer } from 'buffer';
// global.Buffer = Buffer;
// Get the root DOM element
const rootElement = document.getElementById('root');
if (rootElement) {
    // Use createRoot to render the application
    const root = createRoot(rootElement);
    root.render(_jsx(React.StrictMode, { children: _jsx(App, {}) }));
}
else {
    console.error("Root element with id 'root' not found.");
}
