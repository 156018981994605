var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { getFirestore, doc, setDoc } from 'firebase/firestore'; // Firestore imports
import axios from '../api/axiosInstance'; // For MySQL API requests
import '../styles/registermodal.css';
const RegisterModal = ({ onClose, onRegisterSuccess }) => {
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [profileImage, setProfileImage] = useState(null);
    const [error, setError] = useState('');
    const auth = getAuth();
    const handleImageUpload = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!profileImage)
            return '';
        try {
            const formData = new FormData();
            formData.append('profileImage', profileImage);
            const response = yield axios.post('/users/users/upload-profile-image', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            console.log('Image uploaded successfully:', response.data.imageUrl);
            return response.data.imageUrl; // Return the uploaded image URL
        }
        catch (err) {
            console.error('Error uploading image:', err);
            setError('Failed to upload profile image.');
            throw err; // Re-throw error for better error handling
        }
    });
    const handleRegister = () => __awaiter(void 0, void 0, void 0, function* () {
        if (password !== confirmPassword) {
            setError('Passwords do not match!');
            return;
        }
        try {
            // Step 1: Upload Profile Image
            const profileImageUrl = profileImage ? yield handleImageUpload() : '';
            // Step 2: Create Firebase User
            const userCredential = yield createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            // Step 3: Update Firebase User Profile
            yield updateProfile(user, {
                displayName: username,
                photoURL: profileImageUrl, // Attach the profile image URL
            });
            console.log('Firebase user created:', user);
            // Step 4: Insert into MySQL
            yield axios.post('/users/users', {
                firebase_uid: user.uid,
                username,
                email,
                profile_image: profileImageUrl, // Store the image URL in MySQL
            });
            console.log('User saved to MySQL successfully!');
            // Step 5: Update Firestore Document
            const db = getFirestore();
            const userDocRef = doc(db, 'users', user.uid);
            yield setDoc(userDocRef, {
                active: true,
                displayName: username,
                role: 'admin', // Default role
            });
            console.log('Firestore document updated successfully!');
            // Notify parent component of success
            onRegisterSuccess();
        }
        catch (err) {
            console.error('Registration error:', err);
            setError(err.message || 'Registration failed. Please try again.');
        }
    });
    const handleImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setProfileImage(event.target.files[0]);
        }
    };
    return (_jsx("div", { className: "register-modal-overlay", onClick: onClose, children: _jsxs("div", { className: "register-modal", onClick: (e) => e.stopPropagation(), children: [_jsx("h2", { children: "Create Account" }), error && _jsx("p", { className: "register-error", children: error }), _jsxs("div", { className: "register-field", children: [_jsx("label", { children: "Email" }), _jsx("input", { type: "email", value: email, onChange: (e) => setEmail(e.target.value), placeholder: "Enter your email" })] }), _jsxs("div", { className: "register-field", children: [_jsx("label", { children: "Username" }), _jsx("input", { type: "text", value: username, onChange: (e) => setUsername(e.target.value), placeholder: "Enter your username" })] }), _jsxs("div", { className: "register-field", children: [_jsx("label", { children: "Password" }), _jsx("input", { type: "password", value: password, onChange: (e) => setPassword(e.target.value), placeholder: "Enter your password" })] }), _jsxs("div", { className: "register-field", children: [_jsx("label", { children: "Confirm Password" }), _jsx("input", { type: "password", value: confirmPassword, onChange: (e) => setConfirmPassword(e.target.value), placeholder: "Confirm your password" })] }), _jsxs("div", { className: "register-field", children: [_jsx("label", { children: "Profile Image" }), _jsx("input", { type: "file", accept: "image/*", onChange: handleImageChange })] }), _jsxs("div", { className: "register-actions", children: [_jsx("button", { className: "register-btn", onClick: handleRegister, children: "Register" }), _jsx("button", { className: "cancel-btn", onClick: onClose, children: "Cancel" })] })] }) }));
};
export default RegisterModal;
