var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../firebase/firebase';
import { doc, getDoc } from 'firebase/firestore';
const AuthContext = createContext(undefined);
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [role, setRole] = useState(null);
    const [permissions, setPermissions] = useState(null);
    const [displayName, setDisplayName] = useState(null);
    const [photoURL, setPhotoURL] = useState(null); // New state for photoURL
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            setLoading(true);
            if (user) {
                const idToken = yield user.getIdToken();
                const userDocRef = doc(db, 'users', user.uid);
                try {
                    const userDoc = yield getDoc(userDocRef);
                    if (!userDoc.exists()) {
                        console.error('User document not found in Firestore.');
                        setCurrentUser(null);
                        setRole(null);
                        setPermissions(null);
                        setDisplayName(null);
                        setPhotoURL(null); // Clear photoURL
                    }
                    else {
                        const userData = userDoc.data();
                        if (!userData.active) {
                            console.error('User is not active.');
                            setCurrentUser(null);
                            setRole(null);
                            setPermissions(null);
                            setDisplayName(null);
                            setPhotoURL(null); // Clear photoURL
                            return; // Prevent setting the user if they're not active
                        }
                        setRole(userData.role || null);
                        setDisplayName(userData.displayName || 'User');
                        setPhotoURL(userData.photoURL || null); // Set photoURL from Firestore
                        const roleDocRef = doc(db, 'roles', userData.role);
                        const roleDoc = yield getDoc(roleDocRef);
                        if (roleDoc.exists()) {
                            setPermissions(((_a = roleDoc.data()) === null || _a === void 0 ? void 0 : _a.permissions) || {});
                        }
                        else {
                            console.error('Role document not found in Firestore.');
                            setPermissions(null);
                        }
                        setCurrentUser(user);
                    }
                }
                catch (error) {
                    console.error('Error fetching user data from Firestore:', error);
                    setCurrentUser(null);
                    setRole(null);
                    setPermissions(null);
                    setDisplayName(null);
                    setPhotoURL(null); // Clear photoURL
                }
            }
            else {
                setCurrentUser(null);
                setRole(null);
                setPermissions(null);
                setDisplayName(null);
                setPhotoURL(null); // Clear photoURL
            }
            setLoading(false);
        }));
        return () => unsubscribe();
    }, []);
    const value = {
        currentUser,
        loading,
        role,
        permissions,
        displayName,
        photoURL, // Include photoURL in the context
    };
    return _jsx(AuthContext.Provider, { value: value, children: !loading && children });
};
