var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import BarChartComponent from '../components/BarChart';
import axiosInstance from '../api/axiosInstance';
import '/src/styles/chartstyles.css'; // Import the new chart styles
const Dashboard = () => {
    const [labels, setLabels] = useState([]);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const fetchCompletedChores = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const response = yield axiosInstance.get('/api/all-completed-chores', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
                    },
                });
                const chores = response.data;
                console.log('API Response:', chores); // Debug API response
                // Extract labels (chore names) and data (points)
                const choreLabels = chores.map((chore) => chore.name);
                const choreData = chores.map((chore) => chore.points);
                console.log('Labels:', choreLabels); // Debug labels
                console.log('Data:', choreData); // Debug data
                setLabels(choreLabels);
                setData(choreData);
            }
            catch (error) {
                console.error('Error fetching completed chores:', error);
            }
            finally {
                setIsLoading(false);
            }
        });
        fetchCompletedChores();
    }, []);
    return (_jsx("div", { className: "chart-grid-container", children: isLoading ? (_jsx("p", { children: "Loading..." })) : (_jsx("div", { className: "chart-grid-item", children: _jsxs("div", { children: [_jsx("h3", { className: "chart-title", children: "Completed Chores Overview" }), _jsx(BarChartComponent, { labels: labels, data: data })] }) })) }));
};
export default Dashboard;
