var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import "../firebase/firebase"; // Import Firebase initialization file
const LoginModal = ({ onClose, onLoginSuccess }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const handleLogin = (event) => __awaiter(void 0, void 0, void 0, function* () {
        event.preventDefault();
        try {
            const auth = getAuth(); // Get the auth instance from Firebase
            const userCredential = yield signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            const idToken = yield user.getIdToken();
            console.log("Firebase ID Token:", idToken);
            // Send ID token to the backend
            const response = yield fetch('http://localhost:3002/auth/login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ idToken }),
                credentials: 'include',
            });
            if (response.ok) {
                onLoginSuccess();
                onClose();
            }
            else {
                const errorData = yield response.json();
                setError(errorData.message || 'Login failed');
            }
        }
        catch (err) {
            console.error("Firebase login error:", err);
            setError('Invalid email or password.');
        }
    });
    return (_jsx("div", { id: "login-modal", children: _jsxs("div", { className: "modal-content", children: [_jsx("h3", { children: "Login" }), _jsxs("form", { onSubmit: handleLogin, children: [_jsx("label", { children: "Email:" }), _jsx("input", { type: "email", value: email, onChange: (e) => setEmail(e.target.value), required: true }), _jsx("label", { children: "Password:" }), _jsx("input", { type: "password", value: password, onChange: (e) => setPassword(e.target.value), required: true }), error && _jsx("p", { className: "error", children: error }), _jsxs("div", { className: "modal-buttons", children: [_jsx("button", { className: "cancel-btn", type: "button", onClick: () => onClose(), children: "Cancel" }), _jsx("button", { type: "submit", children: "Login" })] })] })] }) }));
};
export default LoginModal;
