import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import '../../styles/themecomponentstyles.css';
import BackButton from '../BackButton';
const ThemeAppearanceSettings = ({ onBack }) => {
    const themes = [
        { name: 'Light', className: 'theme-light' },
        { name: 'Dark', className: 'theme-dark' },
        { name: 'Gaming', className: 'theme-gaming' },
        { name: 'Elegant', className: 'theme-elegant' },
    ];
    const [currentTheme, setCurrentTheme] = useState('theme-light');
    // Apply the selected theme
    const applyTheme = (themeClass) => {
        document.documentElement.classList.remove(...themes.map(t => t.className));
        document.documentElement.classList.add(themeClass);
        setCurrentTheme(themeClass);
        localStorage.setItem('selectedTheme', themeClass); // Save theme to localStorage
    };
    // Load the saved theme on mount
    useEffect(() => {
        const savedTheme = localStorage.getItem('selectedTheme') || 'theme-light';
        applyTheme(savedTheme);
    }, []);
    return (_jsxs("section", { className: "theme-appearance-settings", children: [_jsx(BackButton, { onClick: onBack }), _jsx("h2", { children: "Theme and Appearance" }), _jsx("p", { children: "Select a theme to customize your experience:" }), _jsx("div", { className: "theme-selector", children: themes.map((theme) => (_jsx("button", { className: `theme-button ${theme.className === currentTheme ? 'active' : ''}`, onClick: () => applyTheme(theme.className), children: theme.name }, theme.className))) }), _jsx("style", { children: `
                .theme-selector {
                    display: flex;
                    gap: 10px;
                }
                .theme-button {
                    padding: 10px 20px;
                    border: none;
                    cursor: pointer;
                    border-radius: 4px;
                    font-weight: bold;
                }
                .theme-button.active {
                    border: 2px solid #000;
                }
                ` })] }));
};
export default ThemeAppearanceSettings;
