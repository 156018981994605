import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "../styles/profiledropdown.css";
const ProfileDropdown = ({ isOpen, onClose, onLogout, }) => {
    const dropdownRef = useRef(null);
    const navigate = useNavigate(); // Initialize navigation
    // Close dropdown if clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isOpen && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                onClose();
            }
        };
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [isOpen, onClose]);
    if (!isOpen)
        return null; // Don't render if not open
    const handleProfileClick = () => {
        onClose(); // Close the dropdown
        navigate("/profile"); // Navigate to the ProfilePage
    };
    const handleSettingsClick = () => {
        onClose(); // Close the dropdown
        navigate("/settings"); // Navigate to the ProfilePage
    };
    return (_jsx("div", { className: "dropdown-menu", ref: dropdownRef, children: _jsxs("ul", { children: [_jsx("li", { onClick: handleProfileClick, children: "Profile" }), _jsx("li", { onClick: handleSettingsClick, children: "Settings" }), _jsx("li", { onClick: onLogout, children: "Logout" })] }) }));
};
export default ProfileDropdown;
