var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import sanityClient from '../api/sanityClient';
import MainPageMiddleColumn from '../components/MainPageMiddleColumn';
import Header from '../components/Header';
import LoginModal from '../components/LoginModal';
import RegisterModal from '../components/RegisterModal';
import OnboardingWizard from '../components/Wizard/OnboardingWIzard';
import '../styles/homepage.css';
import '../styles/wizard.css';
const Homepage = () => {
    const auth = getAuth();
    const currentUser = auth.currentUser; // Retrieve the logged-in user
    const [announcements, setAnnouncements] = useState([]);
    const [isLoginModalVisible, setLoginModalVisible] = useState(false);
    const [isRegisterModalVisible, setRegisterModalVisible] = useState(false);
    const [isOnboardingWizardVisible, setOnboardingWizardVisible] = useState(false);
    useEffect(() => {
        fetchAnnouncements();
    }, []);
    const fetchAnnouncements = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const data = yield sanityClient.fetch(`*[_type == "announcement"] | order(publishedAt desc)`);
            setAnnouncements(data);
        }
        catch (error) {
            console.error('Error fetching announcements:', error);
        }
    });
    const handleOpenLoginModal = () => setLoginModalVisible(true);
    const handleCloseLoginModal = () => setLoginModalVisible(false);
    const handleLoginSuccess = () => {
        console.log('User logged in successfully');
        setLoginModalVisible(false);
    };
    const handleOpenRegisterModal = () => setRegisterModalVisible(true);
    const handleCloseRegisterModal = () => setRegisterModalVisible(false);
    const handleRegisterSuccess = () => {
        console.log('User registered successfully');
        setRegisterModalVisible(false);
        setOnboardingWizardVisible(true); // Open onboarding wizard after registration
    };
    const handleOpenOnboardingWizard = () => setOnboardingWizardVisible(true);
    const handleCloseOnboardingWizard = () => setOnboardingWizardVisible(false);
    return (_jsxs("div", { children: [_jsx(Header, { title: "Automagical Household", onSignInClick: handleOpenLoginModal, onRegisterClick: handleOpenRegisterModal }), isLoginModalVisible && (_jsx(LoginModal, { onClose: handleCloseLoginModal, onLoginSuccess: handleLoginSuccess })), isRegisterModalVisible && (_jsx(RegisterModal, { onClose: handleCloseRegisterModal, onRegisterSuccess: handleRegisterSuccess })), isOnboardingWizardVisible && currentUser && (_jsx(OnboardingWizard, { onClose: handleCloseOnboardingWizard, userId: currentUser.uid })), _jsxs("div", { className: "parent", children: [_jsxs("div", { id: "homepage-left", children: [_jsx("h2", { children: "Action Items" }), _jsx("p", { children: "This is where you can find your things that need to be done." }), _jsx("button", { id: "open-onboarding-wizard-button", onClick: handleOpenOnboardingWizard, children: "Open Onboarding Wizard" })] }), _jsx("div", { id: "homepage-middle", children: _jsx(MainPageMiddleColumn, {}) }), _jsxs("div", { id: "homepage-right", children: [_jsx("h2", { children: "Latest Announcements" }), announcements.length > 0 ? (announcements.map((announcement, index) => (_jsxs("div", { className: "announcement", children: [_jsx("h3", { children: announcement.title }), _jsx("p", { children: announcement.content })] }, index)))) : (_jsx("p", { children: "No announcements at the moment." }))] })] })] }));
};
export default Homepage;
