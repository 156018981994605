import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import ThemeAppearanceSettings from '../components/Settings/ThemeAppearanceSettings';
const ThemeManagementPage = ({ onBack }) => {
    const [currentTheme, setCurrentTheme] = useState('theme-light');
    useEffect(() => {
        // Load the saved theme from localStorage
        const savedTheme = localStorage.getItem('selectedTheme') || 'theme-light';
        setCurrentTheme(savedTheme);
    }, []);
    return (_jsx("div", { className: `theme-management-page ${currentTheme}`, children: _jsx(ThemeAppearanceSettings, { onBack: onBack }) }));
};
export default ThemeManagementPage;
