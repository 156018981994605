var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from "react-router-dom";
// import { useLocation } from "react-router-dom";
import '../styles/profilemodal.css'; // You can create this CSS file for styling
import '../styles/profilepage.css';
import axiosInstance from "../api/axiosInstance";
const ProfilePage = ({ user }) => {
    const navigate = useNavigate();
    const handleLogout = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            // Make an API call to logout
            yield axiosInstance.post("/auth/logout");
            // Clear local authentication data
            localStorage.removeItem("authToken");
            // Redirect to login page
            navigate("/login");
        }
        catch (error) {
            console.error("Logout failed:", error);
            alert("Failed to log out. Please try again.");
        }
    });
    const handleClose = () => {
        navigate(-1); // Go back to the previous page
    };
    return (_jsxs("div", { className: "profile-page", children: [_jsx("button", { className: "close-button", onClick: handleClose, children: "\u00D7" }), _jsxs("div", { className: "profile-header", children: [_jsx("img", { src: user.profileImage || "/default-profile.png", alt: `${user.name}'s profile`, className: "profile-image" }), _jsx("h1", { children: user.name })] }), _jsxs("div", { className: "profile-details", children: [_jsxs("p", { children: [_jsx("strong", { children: "Email:" }), " ", user.email] }), user.phoneNumber && _jsxs("p", { children: [_jsx("strong", { children: "Phone:" }), " ", user.phoneNumber] }), user.address && _jsxs("p", { children: [_jsx("strong", { children: "Address:" }), " ", user.address] }), user.bio && _jsxs("p", { children: [_jsx("strong", { children: "About Me:" }), " ", user.bio] })] }), _jsxs("div", { className: "profile-actions", children: [_jsx("button", { onClick: () => alert("Edit Profile clicked!"), children: "Edit Profile" }), _jsx("button", { onClick: handleLogout, children: "Log Out" })] })] }));
};
export default ProfilePage;
