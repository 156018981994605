var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import axiosProxyInstance from '../../api/axiosProxyInstance'; // Use the working proxy instance
import IntegrationCard from './IntegrationCard';
import axios from 'axios';
const IntegrationList = ({ firebaseUid }) => {
    const [integrations, setIntegrations] = useState([
        {
            name: 'Home Assistant',
            status: 'Checking...', // Initial status while fetching
            description: 'Manage your smart home devices with Home Assistant.',
            image: '/media/homeassistant.png',
        },
    ]);
    useEffect(() => {
        const fetchIntegrationStatus = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            try {
                // Make the call using the proxy instance
                const response = yield axiosProxyInstance.get('/api/integrations', {
                    params: {
                        firebase_uid: firebaseUid,
                        integration_name: 'Home Assistant',
                    },
                });
                if (response.data) {
                    const homeAssistantIntegration = response.data;
                    setIntegrations((prevIntegrations) => prevIntegrations.map((integration) => {
                        if (integration.name === 'Home Assistant') {
                            return Object.assign(Object.assign({}, integration), { status: homeAssistantIntegration.base_url && homeAssistantIntegration.token
                                    ? 'Connected'
                                    : 'Not Connected', errorDetails: '' });
                        }
                        return integration;
                    }));
                }
                else {
                    console.warn('No data returned for Home Assistant integration.');
                }
            }
            catch (error) {
                // Use axios.isAxiosError to check for Axios errors
                if (axios.isAxiosError(error)) {
                    setIntegrations((prevIntegrations) => prevIntegrations.map((integration) => {
                        var _a, _b;
                        return integration.name === 'Home Assistant'
                            ? Object.assign(Object.assign({}, integration), { status: 'Error', errorDetails: ((_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) || 'Unknown error occurred.' }) : integration;
                    }));
                    console.error('Axios error:', ((_a = error.response) === null || _a === void 0 ? void 0 : _a.data) || error.message);
                }
                else {
                    // Handle non-Axios errors
                    setIntegrations((prevIntegrations) => prevIntegrations.map((integration) => integration.name === 'Home Assistant'
                        ? Object.assign(Object.assign({}, integration), { status: 'Error', errorDetails: 'An unexpected error occurred.' }) : integration));
                    console.error('Unexpected error:', error);
                }
            }
        });
        fetchIntegrationStatus();
    }, [firebaseUid]);
    return (_jsx("div", { className: "integration-list", children: integrations.map((integration, index) => (_jsx(IntegrationCard, { name: integration.name, status: integration.status, description: integration.description, image: integration.image, errorDetails: integration.errorDetails }, index))) }));
};
export default IntegrationList;
