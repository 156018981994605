import axios from 'axios';
//|| 'http://localhost:3002'
const axiosInstance = axios.create({
    baseURL: 'https://automagical-household-b0771.web.app/', // Set your backend base URL
    withCredentials: true, // Allow cookies to be sent with requests
    headers: {
        'Content-Type': 'application/json', // Default content type
    },
});
export default axiosInstance;
