import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
const IntegrationCard = ({ name, status, description, image, errorDetails }) => {
    // Determine the icon and color based on the status
    let statusIcon;
    let statusColor;
    let statusMessage = status;
    switch (status) {
        case 'Connected':
            statusIcon = '✅'; // Green check
            statusColor = 'green';
            break;
        case 'Not Connected':
            statusIcon = '❌'; // Red X
            statusColor = 'red';
            break;
        case 'Checking...':
            statusIcon = '⏳'; // Hourglass icon
            statusColor = 'orange'; // Amber/Orange for checking
            break;
        case 'Error':
            statusIcon = '❌'; // Red X for error
            statusColor = 'red';
            statusMessage = 'Disconnected (Error)';
            break;
        default:
            statusIcon = '❓'; // Question mark for unknown status
            statusColor = 'gray';
            break;
    }
    return (_jsxs("div", { className: "integration-card", children: [_jsx("img", { src: image, alt: `${name} logo`, className: "integration-card-image" }), _jsx("h2", { children: name }), _jsx("p", { children: description }), _jsxs("p", { style: { color: statusColor, fontWeight: 'bold' }, children: [statusIcon, " ", statusMessage] }), status === 'Error' && errorDetails && (_jsx("p", { style: { color: 'red', fontStyle: 'italic', marginTop: '10px' }, children: errorDetails }))] }));
};
export default IntegrationCard;
