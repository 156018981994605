var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import axios from 'axios';
import CategoryFilter from '../components/Inventory/CategoryFilter';
import InventoryGrid from '../components/Inventory/InventoryGrid';
import InventoryDetails from '../components/Inventory/InventoryDetails';
import DeckManager from '../components/Inventory/DeckManager';
import '../styles/inventory.css'; // Ensure CSS is applied
import { getAuth, onAuthStateChanged } from 'firebase/auth';
const InventoryPage = () => {
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [selectedItem, setSelectedItem] = useState(null); // Track selected item
    const [userInventory, setUserInventory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [deck, setDeck] = useState([]); // State to track the deck
    const [userXp, setUserXp] = useState(50); // Default user XP set to 50
    const maxXp = 100; // Maximum XP value
    const [firebaseUid, setFirebaseUid] = useState(null);
    // UseEffect for tracking authentication state
    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                console.log('Authenticated User UID:', user.uid); // Log UID if user is authenticated
                setFirebaseUid(user.uid); // Update the state with the user UID
            }
            else {
                console.log('No user authenticated'); // Log when no user is authenticated
                setFirebaseUid(null); // Reset firebaseUid if no user is authenticated
            }
        });
        // Cleanup on component unmount
        return () => unsubscribe();
    }, []);
    useEffect(() => {
        const fetchInventory = () => __awaiter(void 0, void 0, void 0, function* () {
            setLoading(true);
            setError(null);
            try {
                if (!firebaseUid) {
                    console.log('No Firebase UID found, cannot fetch inventory'); // Log if no Firebase UID
                    return;
                }
                console.log('Fetching inventory for user UID:', firebaseUid); // Log the user UID used for fetching inventory
                const response = yield axios.get(`http://localhost:3002/api/inventory/${firebaseUid}`);
                setUserInventory(response.data);
            }
            catch (error) {
                setError('Failed to load inventory');
                console.error('Error fetching inventory:', error); // Log error while fetching inventory
            }
            finally {
                setLoading(false);
            }
        });
        if (firebaseUid) {
            fetchInventory();
        }
        // Load the initial deck from localStorage when the component mounts
        const storedDeck = JSON.parse(localStorage.getItem('userDeck') || '[]');
        console.log('Loaded deck from localStorage:', storedDeck); // Log the loaded deck from localStorage
        setDeck(storedDeck);
    }, [firebaseUid]); // Trigger this effect when the firebaseUid state changes
    const filteredInventory = selectedCategory === 'All'
        ? userInventory // Show all items if 'All' is selected
        : userInventory.filter(item => item.category === selectedCategory); // Filter by selected category
    // Add item to the deck and save it to localStorage
    const addToDeck = (item) => {
        console.log('Adding item to deck:', item); // Log the item being added to the deck
        const newDeck = [...deck, item]; // Add the selected item to the deck
        setDeck(newDeck); // Update the state
        localStorage.setItem('userDeck', JSON.stringify(newDeck)); // Save the updated deck to localStorage
        console.log('Deck after item added:', newDeck); // Log the updated deck
    };
    // Save the deck to the server when the "Save" button is pressed
    const saveDeck = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (!firebaseUid) {
                alert('User is not authenticated');
                return;
            }
            const deckItems = JSON.parse(localStorage.getItem('userDeck') || '[]'); // Retrieve the deck items from localStorage
            console.log('Deck items to save:', deckItems); // Log the deck items
            if (!deckItems.length) {
                alert('No items in the deck');
                return;
            }
            const deckName = 'My New Deck'; // Set the deck name
            console.log('Saving deck with name:', deckName); // Log the deck name
            // Send request to create deck and save items
            const deckResponse = yield axios.post('http://localhost:3002/api/user_decks', {
                firebase_uid: firebaseUid,
                deck_name: deckName,
                deckItems, // Pass the array of deck items
            });
            if (deckResponse.status === 200) {
                alert('Deck saved successfully');
            }
            else {
                alert('Failed to save deck');
            }
        }
        catch (error) {
            console.error('Error saving deck:', error); // Log the error while saving the deck
            alert('An error occurred while saving the deck');
        }
    });
    if (loading) {
        return _jsx("div", { children: "Loading..." });
    }
    if (error) {
        return _jsx("div", { children: error });
    }
    return (_jsxs("div", { className: "inventory-page", id: "inventory-page", children: [_jsxs("div", { className: "xp-progress-container", children: [_jsx("div", { className: "xp-progress-bar", style: {
                            width: `${(userXp / maxXp) * 100}%`,
                            backgroundColor: '#32CD32', // Bright green color
                        } }), _jsxs("h3", { id: "user-xp-end", children: [userXp, " XP"] })] }), _jsx("div", { className: "section-a", id: "category-filter-section", children: _jsx(CategoryFilter, { selectedCategory: selectedCategory, setSelectedCategory: setSelectedCategory }) }), _jsx("div", { className: "section-b", id: "inventory-grid-section", children: _jsx(InventoryGrid, { items: filteredInventory, onSelectItem: setSelectedItem }) }), _jsx("div", { className: "section-c", id: "inventory-details-section", children: _jsx(InventoryDetails, { item: selectedItem, onAddToDeck: addToDeck, xp: userXp, maxXp: maxXp }) }), _jsxs("div", { className: "section-d", id: "deck-manager-section", children: [_jsx(DeckManager, { deck: deck }), "  ", _jsx("button", { className: "save-deck-btn", id: "save-deck-btn", onClick: saveDeck, children: "Save Deck" }), "  "] })] }));
};
export default InventoryPage;
