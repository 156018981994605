// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Dropdown Menu */
.dropdown-menu {
    position: absolute;
    top: 50px; /* Position below the profile image */
    right: 0;
    background-color: #2a2a2a;
    color: #fff;
    border: 1px solid #444;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 10px;
    z-index: 1000;
    min-width: 150px;
    display: flex;
    flex-direction: column;
  }
  
  .dropdown-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .dropdown-menu li {
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .dropdown-menu li:hover {
    background-color: #444;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/profiledropdown.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;IACI,kBAAkB;IAClB,SAAS,EAAE,qCAAqC;IAChD,QAAQ;IACR,yBAAyB;IACzB,WAAW;IACX,sBAAsB;IACtB,kBAAkB;IAClB,wCAAwC;IACxC,aAAa;IACb,aAAa;IACb,gBAAgB;IAChB,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,gBAAgB;IAChB,SAAS;IACT,UAAU;EACZ;;EAEA;IACE,kBAAkB;IAClB,eAAe;IACf,sCAAsC;EACxC;;EAEA;IACE,sBAAsB;EACxB","sourcesContent":["/* Dropdown Menu */\r\n.dropdown-menu {\r\n    position: absolute;\r\n    top: 50px; /* Position below the profile image */\r\n    right: 0;\r\n    background-color: #2a2a2a;\r\n    color: #fff;\r\n    border: 1px solid #444;\r\n    border-radius: 8px;\r\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\r\n    padding: 10px;\r\n    z-index: 1000;\r\n    min-width: 150px;\r\n    display: flex;\r\n    flex-direction: column;\r\n  }\r\n  \r\n  .dropdown-menu ul {\r\n    list-style: none;\r\n    margin: 0;\r\n    padding: 0;\r\n  }\r\n  \r\n  .dropdown-menu li {\r\n    padding: 10px 15px;\r\n    cursor: pointer;\r\n    transition: background-color 0.2s ease;\r\n  }\r\n  \r\n  .dropdown-menu li:hover {\r\n    background-color: #444;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
