var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import axiosProxyInstance from '../../api/axiosProxyInstance'; // Importing your custom axios instance
const InventoryDetails = ({ item, onAddToDeck, xp, maxXp }) => {
    // Calculate the XP bar width as a percentage
    const xpPercentage = (xp / maxXp) * 100;
    // Function to call the endpoint when activating a card
    const handleActivateCard = (item) => __awaiter(void 0, void 0, void 0, function* () {
        // Ensure the item has a home_automation_id
        if (!item.home_automation_id || item.home_automation_id === "0") {
            console.error('Error: Invalid home_automation_id in the item.');
            alert('Failed to activate card: Invalid home automation ID.');
            return;
        }
        const payload = {
            user_id: 2, // Replace with dynamic data later
            webhook_id: item.home_automation_id, // Get from the card's attribute
            data: {
                rgb_color: [255, 0, 0], // Replace with dynamic data later
            },
        };
        try {
            const response = yield axiosProxyInstance.post('/api/proxy-webhook', payload);
            console.log('Response:', response.data);
            alert('Card activated successfully!');
        }
        catch (error) {
            console.error('Error activating card:', error);
            alert('Failed to activate card.');
        }
    });
    return (_jsxs("div", { className: "item-details", id: "item-details", children: [_jsx("div", { id: "xp-progress-container", children: _jsx("div", { id: "xp-progress-bar", style: {
                        width: `${xpPercentage}%`,
                        backgroundColor: 'brightgreen', // Adjust for desired brightness
                    } }) }), item ? (_jsxs(_Fragment, { children: [_jsx("h2", { id: "item-name", children: item.name }), _jsx("p", { id: "item-description", children: item.description }), _jsxs("p", { id: "item-quantity", children: ["Quantity: ", item.total_quantity] }), _jsxs("p", { id: "item-category", children: ["Category: ", item.category] }), _jsx("button", { id: "add-to-deck-button", onClick: () => onAddToDeck(item), children: "Add to Deck" }), item.home_automation_id && item.home_automation_id !== "0" && (_jsx("button", { id: "activate-card-button", onClick: () => handleActivateCard(item), children: "Activate" }))] })) : (_jsx("div", { id: "inventory-details-message", children: "Select an item to see details" }))] }));
};
export default InventoryDetails;
