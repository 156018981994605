import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
//import sanityClient from "../api/sanityClient";
import "../styles/homepage.css"; // Assuming styles for the layout are here
const MainPageMiddleColumn = () => {
    var _a, _b;
    const [content, setContent] = useState(null);
    // useEffect(() => {
    //   const fetchContent = async () => {
    //     try {
    //       const data = await sanityClient.fetch(`
    //       *[_type == "middleColumn"][0]{
    //         headerImage{
    //           asset->{
    //             url
    //           }
    //         },
    //         richText[] {
    //           ...,
    //           children[] {
    //             text
    //           }
    //         }
    //       }`);
    //       setContent(data);
    //     } catch (error) {
    //       console.error("Error fetching middle column content:", error);
    //     }
    //   };
    //   fetchContent();
    // }, []);
    if (!content) {
        return _jsx("p", { children: "Loading content..." });
    }
    return (_jsxs("div", { className: "middle-column", children: [((_a = content.headerImage) === null || _a === void 0 ? void 0 : _a.asset.url) && (_jsx("img", { src: content.headerImage.asset.url, alt: "Header", className: "header-image" })), (_b = content.richText) === null || _b === void 0 ? void 0 : _b.map((block) => {
                var _a;
                return (_jsx("p", { children: (_a = block.children) === null || _a === void 0 ? void 0 : _a.map((child) => child.text) }, block._key));
            })] }));
};
export default MainPageMiddleColumn;
