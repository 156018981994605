import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from 'firebase/auth';
const firebaseConfig = {
    apiKey: "AIzaSyDuqhqqMqwXhxLI2MexKTFwN4voWj_jiGU",
    authDomain: "automagical-household-b0771.firebaseapp.com",
    projectId: "automagical-household-b0771",
    storageBucket: "automagical-household-b0771.firebasestorage.app",
    messagingSenderId: "1050599169603",
    appId: "1:1050599169603:web:b64159e5ba6418afe53ef6",
    measurementId: "G-8BJSW2D1VL"
};
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
//const analytics = getAnalytics(firebaseApp );
const db = getFirestore(firebaseApp); // Initialize Firestore
const auth = getAuth(firebaseApp); // Initialize Firebase Auth
export { firebaseApp, auth, db };
