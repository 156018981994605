import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
// Example HouseGraphic component (import your actual component)
const HouseGraphic = ({ admin, members, icons }) => {
    if (!admin || !members || !icons) {
        return _jsx("p", { children: "No data available to render the SVG." });
    }
    return (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", viewBox: "0 0 375 375", width: "100%", height: "100%", preserveAspectRatio: "xMidYMid meet", style: { border: '1px solid #000', maxWidth: '400px', height: 'auto' }, children: [_jsx("g", { children: _jsx("path", { fill: "#FFD700", d: "M 187.457031 20.132812 L 33.3125 174.515625 C 31.023438 176.8125 32.648438 180.730469 35.890625 180.730469 L 62.277344 180.730469 C 64.574219 180.730469 66.433594 182.589844 66.433594 184.882812 L 66.433594 354.652344 L 308.164062 354.652344 L 308.164062 184.882812 C 308.164062 182.589844 310.023438 180.730469 312.3125 180.730469 L 339.023438 180.730469 C 342.269531 180.730469 343.898438 176.8125 341.601562 174.515625 L 289.277344 122.109375 L 289.277344 59.1875 C 289.277344 56.476562 287.074219 54.273438 284.355469 54.273438 L 244.023438 54.273438 C 241.304688 54.273438 239.105469 56.476562 239.105469 59.1875 L 239.105469 68.875 C 239.105469 69.617188 238.496094 70.113281 237.859375 70.113281 C 237.558594 70.113281 237.25 69.996094 236.996094 69.746094 Z M 187.457031 28.480469 L 232.816406 73.914062 C 234.167969 75.265625 235.960938 76.011719 237.859375 76.011719 C 241.800781 76.011719 245.007812 72.808594 245.007812 68.875 L 245.007812 60.171875 L 283.375 60.171875 L 283.375 124.546875 L 285.097656 126.273438 L 333.578125 174.832031 L 312.3125 174.832031 C 306.773438 174.832031 302.261719 179.339844 302.261719 184.882812 L 302.261719 348.753906 L 72.335938 348.753906 L 72.335938 184.882812 C 72.335938 179.339844 67.824219 174.832031 62.277344 174.832031 L 41.335938 174.832031 L 187.457031 28.480469 " }) }), _jsx("circle", { cx: "187.5", cy: "200", r: "30", fill: "gray" }), _jsx("text", { x: "187.5", y: "210", fontSize: "14", textAnchor: "middle", fill: "white", children: admin.name }), members.map((member, index) => {
                const angle = (index / members.length) * Math.PI * 2;
                const x = 187.5 + 80 * Math.cos(angle); // Adjusted to the house center
                const y = 200 + 80 * Math.sin(angle);
                return (_jsxs(React.Fragment, { children: [_jsx("line", { x1: "187.5", y1: "200", x2: x, y2: y, stroke: "black" }), _jsx("circle", { cx: x, cy: y, r: "20", fill: "blue" }), _jsx("text", { x: x, y: y + 5, fontSize: "10", textAnchor: "middle", fill: "white", children: member.name })] }, member.id));
            }), icons.map((icon, index) => {
                const x = 120 + index * 100; // Spread horizontally at the bottom
                const y = 330; // Fixed Y position
                return (_jsxs(React.Fragment, { children: [_jsx("circle", { cx: x, cy: y, r: "10", fill: icon.active ? 'green' : 'red' }), _jsx("text", { x: x, y: y + 20, fontSize: "10", textAnchor: "middle", fill: "white", children: icon.name || 'Icon' })] }, icon.id));
            })] }));
};
const Summary = ({ onComplete, onBack, firebaseUid }) => {
    const handleComplete = () => {
        // For now, just call onComplete to close the modal
        onComplete();
    };
    // Static data for testing
    const admin = {
        profilePic: 'https://via.placeholder.com/60',
        name: 'Admin',
    };
    const members = [
        { id: '1', profilePic: 'https://via.placeholder.com/40', name: 'Member 1' },
        { id: '2', profilePic: 'https://via.placeholder.com/40', name: 'Member 2' },
    ];
    const icons = [
        { id: '1', iconSrc: 'https://via.placeholder.com/20', active: true, name: 'Integration 1' },
        { id: '2', iconSrc: 'https://via.placeholder.com/20', active: false, name: 'Integration 2' },
    ];
    return (_jsxs("div", { className: "wizard-step", children: [_jsx("h1", { children: "Your Automagical Household" }), _jsx("p", { children: "Here is a summary of your setup!" }), _jsx(HouseGraphic, { admin: admin, members: members, icons: icons }), _jsx("button", { onClick: onBack, children: "Back" }), _jsx("button", { onClick: handleComplete, children: "Complete" })] }));
};
export default Summary;
