var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import Agenda from '../components/Agenda';
import ChoreDetails from '../components/ChoreDetails';
import CompletedChores from '../components/CompletedChores';
import SmallCalendar from '../components/SmallCalendar';
import '../styles/trackchores.css';
import axiosInstance from '../api/axiosInstance';
import { useAuth } from '../contexts/AuthContext';
const TrackChoresPage = () => {
    const [chores, setChores] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedChore, setSelectedChore] = useState(null);
    const [completedChores, setCompletedChores] = useState([]);
    const [totalPoints, setTotalPoints] = useState(0);
    const [userFilter, setUserFilter] = useState('all');
    const [choreDays, setChoreDays] = useState([]);
    const [currentHouseholdId, setCurrentHouseholdId] = useState(null);
    const { currentUser } = useAuth();
    const getAuthHeader = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!currentUser) {
            console.error('User not logged in');
            return null;
        }
        try {
            const idToken = yield currentUser.getIdToken();
            return { Authorization: `Bearer ${idToken}` };
        }
        catch (error) {
            console.error('Error fetching ID token:', error);
            return null;
        }
    });
    const fetchCurrentHousehold = (firebaseUid) => __awaiter(void 0, void 0, void 0, function* () {
        if (!firebaseUid) {
            console.error('Missing firebaseUid parameter.');
            return;
        }
        try {
            const response = yield axiosInstance.get(`/users/users/current-household/${firebaseUid}`);
            setCurrentHouseholdId(response.data.household_id);
        }
        catch (error) {
            console.error('Error fetching current household:', error);
        }
    });
    const fetchUsers = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!currentHouseholdId) {
            console.error('No household ID available');
            return;
        }
        try {
            const response = yield axiosInstance.get(`/users/household-members/${currentHouseholdId}`);
            const householdMembers = response.data;
            console.log('Fetched household members:', householdMembers); // Log the users array
            setUsers(householdMembers); // Update the users state
        }
        catch (error) {
            console.error('Error fetching household members:', error);
        }
    });
    const fetchChores = (...args_1) => __awaiter(void 0, [...args_1], void 0, function* (assignee = null) {
        const authHeader = yield getAuthHeader();
        if (!authHeader)
            return;
        try {
            const params = assignee ? { assignee } : {};
            const response = yield axiosInstance.get('/api/todays-chores', {
                params,
                headers: authHeader,
            });
            setChores(response.data);
        }
        catch (error) {
            console.error("Error fetching today's chores:", error);
        }
    });
    const fetchCompletedChores = () => __awaiter(void 0, void 0, void 0, function* () {
        const authHeader = yield getAuthHeader();
        if (!authHeader)
            return;
        try {
            const response = yield axiosInstance.get('/api/completed-chores', {
                headers: authHeader,
            });
            const data = response.data;
            setCompletedChores(data);
            const total = data.reduce((acc, chore) => acc + chore.points, 0);
            setTotalPoints(total);
        }
        catch (error) {
            console.error('Error fetching completed chores:', error);
        }
    });
    const fetchAllChores = () => __awaiter(void 0, void 0, void 0, function* () {
        const authHeader = yield getAuthHeader();
        if (!authHeader)
            return;
        try {
            const response = yield axiosInstance.get('/api/all-chores', {
                headers: authHeader,
            });
            const allChores = response.data;
            const choreDates = allChores
                .map(chore => { var _a; return (_a = chore.next_instance) === null || _a === void 0 ? void 0 : _a.split('T')[0]; })
                .filter(Boolean);
            setChoreDays([...new Set(choreDates)]);
        }
        catch (error) {
            console.error('Error fetching all chores:', error);
        }
    });
    useEffect(() => {
        if (currentUser === null || currentUser === void 0 ? void 0 : currentUser.uid) {
            fetchCurrentHousehold(currentUser.uid); // Fetch household ID using firebaseUid
        }
    }, [currentUser]);
    useEffect(() => {
        if (currentHouseholdId) {
            fetchUsers();
            fetchChores();
            fetchCompletedChores();
            fetchAllChores();
        }
    }, [currentHouseholdId]);
    const handleChoreSelection = (chore) => {
        setSelectedChore(chore);
    };
    const handleMarkComplete = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!selectedChore) {
            console.error('No chore selected!');
            return;
        }
        const authHeader = yield getAuthHeader();
        if (!authHeader)
            return;
        try {
            yield axiosInstance.post('/api/complete-chore', {
                choreId: selectedChore.id,
                assignee: selectedChore.assignee,
                points: selectedChore.points,
            }, { headers: authHeader });
            fetchChores(userFilter === 'all' ? null : userFilter);
            fetchCompletedChores();
            setSelectedChore(null);
        }
        catch (error) {
            console.error('Error completing chore:', error);
        }
    });
    const handleUserFilterChange = (userId) => {
        setUserFilter(userId);
        fetchChores(userId === 'all' ? null : userId);
    };
    return (_jsxs("div", { children: [_jsx("header", { children: _jsx("h1", { children: "Track Chores" }) }), _jsxs("div", { className: "parent", children: [_jsx("div", { id: "agenda", children: _jsx(Agenda, { chores: chores, users: users, userFilter: userFilter, onUserFilterChange: handleUserFilterChange, onChoreSelect: handleChoreSelection }) }), _jsx("div", { id: "chore-details", children: _jsx(ChoreDetails, { selectedChore: selectedChore, onMarkComplete: handleMarkComplete }) }), _jsx("div", { id: "completed-chores", children: _jsx(CompletedChores, { completedChores: completedChores, totalPoints: totalPoints }) }), _jsx("div", { id: "right-column-top", children: _jsx(SmallCalendar, { choreDays: choreDays }) }), _jsxs("div", { id: "right-column-bottom", children: [_jsx("h2", { children: "Blue Box Content" }), _jsx("p", { children: "Add your content for the blue box here." })] })] })] }));
};
export default TrackChoresPage;
