var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { signOut } from "firebase/auth";
import { auth } from "../firebase/firebase";
import axios from "../api/axiosInstance";
import ProfileDropdown from "./ProfileDropdown";
import "../styles/header.css";
const Header = ({ title, onSignInClick, onRegisterClick }) => {
    const { currentUser } = useAuth();
    const [profileImage, setProfileImage] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    useEffect(() => {
        console.log("Dropdown open state:", isDropdownOpen); // Debug state changes
    }, [isDropdownOpen]);
    useEffect(() => {
        const fetchProfileImage = () => __awaiter(void 0, void 0, void 0, function* () {
            if (currentUser) {
                try {
                    const response = yield axios.get(`/users/profile/${currentUser.uid}`);
                    setProfileImage(response.data.profile_image);
                }
                catch (error) {
                    console.error("Error fetching profile image:", error);
                }
            }
        });
        fetchProfileImage();
    }, [currentUser]);
    const handleLogout = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield signOut(auth);
            console.log("Logged out successfully");
        }
        catch (error) {
            console.error("Logout error:", error);
        }
    });
    const toggleDropdown = (e) => {
        e.stopPropagation(); // Prevent event interference
        console.log("Profile image clicked"); // Debug log
        setIsDropdownOpen((prevState) => !prevState);
    };
    return (_jsxs("header", { className: "header", children: [_jsx("h1", { className: "header-title", children: title }), _jsx("div", { id: "auth-buttons", className: "auth-buttons", children: currentUser ? (_jsxs("div", { className: "profile-container", children: [_jsx("img", { id: "profile-image", src: profileImage || "/media/default-profile.webp", alt: "Profile", onClick: toggleDropdown }), isDropdownOpen && (_jsx(ProfileDropdown, { isOpen: isDropdownOpen, onClose: () => setIsDropdownOpen(false), onLogout: handleLogout }))] })) : (_jsxs("div", { className: "auth-buttons-guest", children: [_jsx("button", { id: "login-btn", className: "auth-button", onClick: onSignInClick, children: _jsx("i", { className: "fas fa-sign-in-alt" }) }), _jsx("button", { id: "register-btn", className: "auth-button", onClick: onRegisterClick, children: _jsx("i", { className: "fas fa-user-plus" }) })] })) })] }));
};
export default Header;
