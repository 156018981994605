import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import LoginModal from './components/LoginModal';
import Sidebar from './components/Sidebar';
import TrackChoresPage from './pages/TrackChoresPage';
import CreateChoresPage from './pages/CreateChoresPage';
import Homepage from './pages/Homepage';
import Dashboard from './pages/Dashboard';
import Store from './pages/StorePage';
import InventoryPage from './pages/InventoryPage';
import CreateCardPage from './pages/CardCreationPage';
import ProfilePage from './pages/ProfilePage';
import SettingsPage from './pages/SettingsPage';
import { AuthProvider } from './contexts/AuthContext';
const App = () => {
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [showRegisterModal, setShowRegisterModal] = useState(false); // State for Register modal
    const toggleLoginModal = () => {
        setShowLoginModal(!showLoginModal);
    };
    const toggleRegisterModal = () => {
        setShowRegisterModal(!showRegisterModal); // Toggle Register modal
    };
    const handleLoginSuccess = () => {
        console.log('User logged in successfully, closing modal...');
        setShowLoginModal(false);
    };
    const handleRegisterSuccess = () => {
        console.log('User registered successfully, closing modal...');
        setShowRegisterModal(false);
    };
    const user = {
        name: "Jane Doe",
        email: "janedoe@example.com",
        profileImage: "/media/default-profile.webp",
        bio: "A software developer who loves coding and coffee.",
        phoneNumber: "123-456-7890",
        address: "123 Main St, Springfield, USA",
    };
    return (_jsx(AuthProvider, { children: _jsx(Router, { children: _jsxs("div", { children: [_jsx(Header, { title: "Automagical Household", onSignInClick: toggleLoginModal, onRegisterClick: toggleRegisterModal }), _jsx(Sidebar, {}), showLoginModal && (_jsx(LoginModal, { onClose: toggleLoginModal, onLoginSuccess: handleLoginSuccess })), showRegisterModal && (_jsx(LoginModal, { onClose: toggleRegisterModal, onLoginSuccess: handleRegisterSuccess })), _jsx("main", { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(Homepage, {}) }), _jsx(Route, { path: "/trackchores", element: _jsx(TrackChoresPage, {}) }), _jsx(Route, { path: "/dashboard", element: _jsx(Dashboard, {}) }), _jsx(Route, { path: "/create-chores", element: _jsx(CreateChoresPage, {}) }), _jsx(Route, { path: "/store", element: _jsx(Store, {}) }), _jsx(Route, { path: "/inventory", element: _jsx(InventoryPage, {}) }), _jsx(Route, { path: "/create-card", element: _jsx(CreateCardPage, {}) }), _jsx(Route, { path: "/profile", element: _jsx(ProfilePage, { user: user }) }), _jsx(Route, { path: "/settings", element: _jsx(SettingsPage, {}) })] }) })] }) }) }));
};
export default App;
