import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
const ChoreTable = ({ chores = [] }) => {
    // State for toggling views
    const [isTableView, setIsTableView] = useState(true);
    // Debugging chores data
    console.log("Chores received in ChoreTable:", chores);
    // Toggle handler
    const toggleView = () => setIsTableView(!isTableView);
    return (_jsxs("div", { children: [_jsx("div", { style: { textAlign: "right", marginBottom: "10px" }, children: _jsxs("button", { onClick: toggleView, style: {
                        padding: "8px 16px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        border: "2px solid yellow",
                        backgroundColor: "transparent",
                        color: "yellow",
                        borderRadius: "5px",
                        fontSize: "1rem",
                        transition: "background-color 0.3s ease, color 0.3s ease",
                    }, onMouseEnter: (e) => {
                        e.currentTarget.style.backgroundColor = "yellow";
                        e.currentTarget.style.color = "black";
                    }, onMouseLeave: (e) => {
                        e.currentTarget.style.backgroundColor = "transparent";
                        e.currentTarget.style.color = "yellow";
                    }, children: [_jsx("i", { className: isTableView ? "fas fa-th" : "fas fa-table" }), isTableView ? "Gallery View" : "Table View"] }) }), isTableView ? (_jsxs("table", { id: "chore-table", children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "Chore Name" }), _jsx("th", { children: "Description" }), _jsx("th", { children: "Points" }), _jsx("th", { children: "Start Date" }), _jsx("th", { children: "Frequency" }), _jsx("th", { children: "End Date" }), _jsx("th", { children: "Assignee" })] }) }), _jsx("tbody", { children: Array.isArray(chores) &&
                            chores.map((chore, index) => (_jsxs("tr", { children: [_jsx("td", { children: chore.name }), _jsx("td", { children: chore.description }), _jsx("td", { children: chore.points }), _jsx("td", { children: chore.start_date }), _jsx("td", { children: chore.frequency }), _jsx("td", { children: chore.end_date }), _jsx("td", { children: chore.assignee || "Unassigned" })] }, index))) })] })) : (_jsx("div", { style: {
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    gap: "10px",
                    padding: "10px",
                }, children: Array.isArray(chores) &&
                    chores.map((chore, index) => (_jsxs("div", { style: {
                            backgroundColor: "#333",
                            color: "#FFEB3B",
                            padding: "10px",
                            borderRadius: "5px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                            textAlign: "center",
                        }, children: [_jsx("h3", { style: { margin: "10px 0" }, children: chore.name }), _jsxs("p", { style: { margin: "5px 0", fontSize: "0.9rem" }, children: [_jsx("strong", { children: "Assignee:" }), " ", chore.assignee || "Unassigned"] }), _jsxs("p", { style: { margin: "5px 0", fontSize: "0.9rem" }, children: [_jsx("strong", { children: "Points:" }), " ", chore.points] })] }, index))) }))] }));
};
export default ChoreTable;
