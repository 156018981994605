var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import axiosInstance from '../../api/axiosInstance';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
const HouseholdSetup = ({ onNext, onBack }) => {
    const [nickname, setNickname] = useState('');
    const [members, setMembers] = useState([]);
    const [newMember, setNewMember] = useState({ name: '', role: 'Child', image: '' });
    const [firebaseUid, setFirebaseUid] = useState(null);
    useEffect(() => {
        const auth = getAuth();
        const currentUser = auth.currentUser;
        if (currentUser) {
            setFirebaseUid(currentUser.uid); // Dynamically set firebase_uid
            fetchHouseholdData(currentUser.uid); // Fetch existing household data
        }
        else {
            console.error('No logged-in user found.');
        }
    }, []);
    const fetchHouseholdData = (uid) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        try {
            const response = yield axiosInstance.get('/household/household', {
                params: { firebase_uid: uid },
            });
            console.log('API Response:', response.data); // Log the response
            if (response.status === 200) {
                const { household, members } = response.data;
                console.log('Household:', household); // Log household data
                console.log('Members:', members); // Log members data
                setNickname(household.nickname);
                setMembers(members);
            }
        }
        catch (error) {
            if (axios.isAxiosError(error)) {
                if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 404) {
                    console.log('No household found for the user.');
                }
                else {
                    console.error('Error fetching household data:', ((_b = error.response) === null || _b === void 0 ? void 0 : _b.data) || error.message);
                }
            }
            else {
                console.error('Unexpected error:', error);
            }
        }
    });
    const addMember = () => {
        if (!newMember.name || !newMember.image) {
            alert('Please provide a name and upload an image for the member.');
            return;
        }
        setMembers([...members, newMember]);
        setNewMember({ name: '', role: 'Child', image: '' }); // Reset inputs after adding
    };
    const handleImageUpload = (file) => {
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setNewMember((prev) => { var _a; return (Object.assign(Object.assign({}, prev), { image: (_a = e.target) === null || _a === void 0 ? void 0 : _a.result })); });
            };
            reader.readAsDataURL(file);
        }
    };
    const handleNext = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!nickname || members.length === 0) {
            alert('Please provide a household nickname and add at least one member.');
            return;
        }
        if (!firebaseUid) {
            alert('Unable to get user authentication details. Please log in again.');
            return;
        }
        try {
            const response = yield axiosInstance.post('/household/household', {
                firebase_uid: firebaseUid,
                nickname,
                members: members.map(({ name, role }) => ({ name, role })), // Remove images before sending to the backend
            });
            if (response.status === 200) {
                console.log('Household created successfully:', response.data);
                onNext({ nickname, members }); // Proceed to the next step
            }
            else {
                console.error('Failed to create household:', response.data);
            }
        }
        catch (error) {
            console.error('Error creating household:', error);
        }
    });
    return (_jsxs("div", { id: "household-setup-step", className: "wizard-step", children: [_jsx("h3", { id: "household-setup-title", children: "Set Up Your Household" }), _jsx("input", { id: "household-nickname-input", type: "text", placeholder: "Household Nickname", value: nickname, onChange: (e) => setNickname(e.target.value) }), _jsx("div", { id: "member-gallery", className: "gallery", children: members.map((member, index) => (_jsxs("div", { className: "gallery-card", children: [member.image ? (_jsx("img", { src: member.image, alt: member.name, className: "member-image" })) : (_jsx("div", { className: "member-placeholder", children: "No Image" }) // Placeholder for missing images
                        ), _jsx("div", { className: "member-name", children: member.name || 'Unnamed' })] }, index))) }), _jsx("h3", { id: "household-members-title", children: "Add a New Member" }), _jsxs("div", { className: "member-entry", children: [_jsx("input", { id: "new-member-name", type: "text", placeholder: "Name", value: newMember.name, onChange: (e) => setNewMember((prev) => (Object.assign(Object.assign({}, prev), { name: e.target.value }))) }), _jsxs("select", { id: "new-member-role", value: newMember.role, onChange: (e) => setNewMember((prev) => (Object.assign(Object.assign({}, prev), { role: e.target.value }))), children: [_jsx("option", { value: "Parent", children: "Parent" }), _jsx("option", { value: "Child", children: "Child" })] }), _jsx("input", { id: "new-member-image", type: "file", accept: "image/*", onChange: (e) => handleImageUpload(e.target.files ? e.target.files[0] : null) })] }), _jsx("button", { id: "add-member-btn", onClick: addMember, children: "Add Member" }), _jsxs("div", { className: "navigation-buttons", children: [_jsx("button", { id: "back-btn", onClick: onBack, children: "Back" }), _jsx("button", { id: "next-btn", onClick: handleNext, children: "Next" })] })] }));
};
export default HouseholdSetup;
