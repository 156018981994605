var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import ChoreForm from "../components/ChoreForm";
import ChoreTable from "../components/ChoreTable";
import { getAuth } from "firebase/auth";
import axiosInstance from "../api/axiosInstance";
import "../styles/create_chores.css";
const CreateChoresPage = () => {
    const [chores, setChores] = useState([]);
    const [users, setUsers] = useState([]); // Updated type
    const [householdId, setHouseholdId] = useState("");
    useEffect(() => {
        const fetchHouseholdData = () => __awaiter(void 0, void 0, void 0, function* () {
            const auth = getAuth();
            const user = auth.currentUser;
            if (user) {
                const firebaseUid = user.uid; // Get firebase_uid from the authenticated user
                const token = yield user.getIdToken();
                try {
                    // Fetch the logged-in user's household ID
                    const householdRes = yield axiosInstance.get(`/users/users/${firebaseUid}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    const fetchedHouseholdId = householdRes.data.household_id;
                    setHouseholdId(fetchedHouseholdId);
                    console.log("Household ID fetched:", fetchedHouseholdId);
                    // Fetch all chores
                    const choreRes = yield axiosInstance.get("/api/chores/all-chores", {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    setChores(choreRes.data);
                    // Fetch household members
                    const memberRes = yield axiosInstance.get(`/users/household-members/${fetchedHouseholdId}`, { headers: { Authorization: `Bearer ${token}` } });
                    setUsers(memberRes.data); // Assuming response contains household members
                }
                catch (err) {
                    console.error("Error fetching data:", err);
                }
            }
        });
        fetchHouseholdData();
    }, []);
    return (_jsxs("div", { id: "create-chores-page", children: [_jsxs("div", { id: "chore-form-section", children: [_jsx("h2", { children: "Create a Chore" }), householdId ? (_jsx(ChoreForm, { onChoreCreated: () => {
                            // Refetch data or add logic to update chores list
                            console.log("Chore created! Refresh or update the chore list.");
                        }, users: users, household_id: householdId })) : (_jsx("p", { children: "Loading household data..." }))] }), _jsxs("div", { id: "chore-table-section", children: [_jsx("h2", { children: "Chores" }), _jsx(ChoreTable, { chores: chores })] })] }));
};
export default CreateChoresPage;
