var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import axios from '../api/axiosInstance';
import '../styles/storestyles.css';
import { getAuth } from 'firebase/auth'; // Import Firebase Auth
const CART_KEY = 'storeCart'; // Key for saving the cart in localStorage
const StorePage = () => {
    const [items, setItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [cart, setCart] = useState(() => {
        // Load cart from localStorage if available
        const savedCart = localStorage.getItem(CART_KEY);
        return savedCart ? JSON.parse(savedCart) : [];
    });
    const [userPoints, setUserPoints] = useState(0);
    const [categoryFilter, setCategoryFilter] = useState('all');
    // Fetch Firebase UID
    const firebaseAuth = getAuth();
    const currentUser = firebaseAuth.currentUser;
    const firebaseUid = (currentUser === null || currentUser === void 0 ? void 0 : currentUser.uid) || ''; // Use Firebase UID
    useEffect(() => {
        const fetchStoreData = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                // Fetch store items
                const itemsResponse = yield axios.get('/api/store-items');
                setItems(itemsResponse.data);
                setFilteredItems(itemsResponse.data);
                // Fetch user points using Firebase UID
                if (firebaseUid) {
                    const walletResponse = yield axios.get(`/api/wallet/${firebaseUid}`);
                    setUserPoints(walletResponse.data.balance_points || 0);
                }
            }
            catch (error) {
                console.error('Error fetching store data:', error);
            }
        });
        fetchStoreData();
    }, [firebaseUid]);
    useEffect(() => {
        // Save cart to localStorage whenever it changes
        localStorage.setItem(CART_KEY, JSON.stringify(cart));
    }, [cart]);
    const handleAddToCart = (item) => {
        setCart((prevCart) => {
            const existingItem = prevCart.find((cartItem) => cartItem.item_id === item.item_id);
            if (existingItem) {
                return prevCart.map((cartItem) => cartItem.item_id === item.item_id
                    ? Object.assign(Object.assign({}, cartItem), { quantity: cartItem.quantity + 1 }) : cartItem);
            }
            return [...prevCart, Object.assign(Object.assign({}, item), { quantity: 1 })];
        });
    };
    const handleCategoryChange = (category) => {
        setCategoryFilter(category);
        setFilteredItems(category === 'all'
            ? items
            : items.filter((item) => item.category === category));
    };
    const handleCheckout = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        if (cart.length === 0) {
            alert('Your cart is empty!');
            return;
        }
        const totalPoints = cart.reduce((total, item) => total + item.points_cost * item.quantity, 0);
        if (totalPoints > userPoints) {
            alert('Not enough points to complete the purchase.');
            return;
        }
        try {
            const response = yield axios.post('/api/checkout', { firebaseUid, cart });
            alert(response.data.message || 'Checkout successful!');
            setCart([]); // Clear the cart
            localStorage.removeItem('storeCart'); // Remove saved cart from localStorage
            // Fetch updated wallet points
            const walletResponse = yield axios.get(`/api/wallet/${firebaseUid}`);
            setUserPoints(walletResponse.data.balance_points || 0);
        }
        catch (error) {
            console.error('Checkout error:', ((_a = error.response) === null || _a === void 0 ? void 0 : _a.data) || error.message);
            alert('Checkout failed. Please try again.');
        }
    });
    const handleRemoveFromCart = (itemId) => {
        setCart((prevCart) => prevCart.filter((item) => item.item_id !== itemId));
    };
    return (_jsxs("div", { className: "store-page", children: [_jsxs("div", { className: "store-header", children: [_jsx("header", { children: _jsx("h1", { children: "Automagical Store" }) }), _jsxs("div", { className: "category-filter", children: [_jsx("label", { htmlFor: "category-select", children: "Filter by Category:" }), _jsxs("select", { id: "category-select", value: categoryFilter, onChange: (e) => handleCategoryChange(e.target.value), children: [_jsx("option", { value: "all", children: "All Categories" }), _jsx("option", { value: "Card", children: "Card" }), _jsx("option", { value: "Power-Up", children: "Power-Up" }), _jsx("option", { value: "Resource", children: "Resource" }), _jsx("option", { value: "IRL Power-Up", children: "IRL Power-Up" }), _jsx("option", { value: "Smarthouse", children: "Smarthouse" })] })] })] }), _jsx("div", { className: "store-items", children: filteredItems.map((item) => (_jsxs("div", { className: "item-card", children: [_jsx("img", { src: item.image_url || '/media/default-item.png', alt: item.name }), _jsx("h3", { children: item.name }), _jsxs("p", { children: ["Cost: ", item.points_cost, " Points"] }), _jsx("button", { onClick: () => handleAddToCart(item), children: "Add to Cart" })] }, item.item_id))) }), _jsxs("div", { className: "store-cart", children: [_jsx("h2", { children: "Shopping Cart" }), _jsxs("p", { id: "user-points-display", children: ["Your Points: ", userPoints] }), _jsx("ul", { children: cart.map((item) => (_jsxs("li", { children: [_jsxs("span", { children: [item.name, " x", item.quantity, " - ", item.points_cost * item.quantity, " Points"] }), _jsx("button", { onClick: () => handleRemoveFromCart(item.item_id), children: "Remove" })] }, item.item_id))) }), _jsxs("p", { children: ["Total: ", cart.reduce((total, item) => total + item.points_cost * item.quantity, 0), " Points"] }), _jsx("button", { onClick: handleCheckout, children: "Checkout" })] })] }));
};
export default StorePage;
